import React from "react";
import { useTranslation } from "react-i18next";
import Block from "../Block";

const SecondSection = () => {
  const { t } = useTranslation();

  return (
    <section className="mt-8 md:mt-[70px] w-full items-center flex flex-col px-4 md:px-0">
      <h2 className="tracking-normal md:tracking-[-1.8px] mb-8 md:mb-[70px] text-center leading-tight md:leading-[58px] text-[#17335D] max-w-[950px] font-bold text-2xl md:text-[48px]">
        {t("hotlineInfo.title")}
      </h2>
      <div className="flex flex-col md:flex-row justify-center gap-6 md:gap-x-[35px] w-full max-w-[1200px]">
        <Block>{t("hotlineInfo.keyPoints.0")}</Block>
        <Block>{t("hotlineInfo.keyPoints.1")}</Block>
        <Block>{t("hotlineInfo.keyPoints.2")}</Block>
      </div>
      <div className="flex flex-col md:flex-row mt-6 md:mt-[60px] justify-center gap-6 md:gap-x-[35px] w-full max-w-[1200px]">
        <Block>{t("hotlineInfo.keyPoints.3")}</Block>
        <Block>{t("hotlineInfo.keyPoints.4")}</Block>
        <Block>{t("hotlineInfo.keyPoints.5")}</Block>
      </div>
    </section>
  );
};

export default SecondSection;
