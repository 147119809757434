/**
 * @file FadeIn.tsx
 * @author Alan Mitnick
 * @description Component and Higher-Order Component (HOC)
 *              for applying fade-in effect to components in the application.
 */
import React, { ReactNode, useEffect, useState } from "react";

// Define the duration for the fade-in effect
const FADE_IN_DURATION = 200; // milliseconds

/**
 * Defines the type for the wrapped component's props when used as an HOC.
 */
type WrappedComponentProps = {
  children?: ReactNode; // Ensure no children prop when used as an HOC
};

/**
 * A higher-order component (HOC) that adds a fade-in effect to the wrapped component.
 *
 * @returns A new component with a fade-in effect applied to the wrapped component.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { withFadeIn } from './FadeIn';
 *
 * // Define a component to be wrapped with the fade-in effect
 * const MyComponent: FC = () => {
 *   return (
 *     <div>
 *       <h1>Hello, World!</h1>
 *     </div>
 *   );
 * };
 *
 * // Export the component wrapped with withFadeIn HOC to apply fade-in effect
 * export default withFadeIn(FadedComponent);
 * ```
 */
function withFadeIn<P extends WrappedComponentProps>(
  WrappedComponent: React.ComponentType<P>,
) {
  return (props: P) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      setIsVisible(true);
    }, []);

    return (
      <div
        className={`transition-opacity duration-${FADE_IN_DURATION} ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };
}

type FadeInProps = {
  children: React.ReactNode; // Define children prop for wrapper component
  className?: string;
};

/**
 * Component that can be used as a wrapper around other components to apply fade-in effect.
 *
 * @example
 * ```tsx
 * // The main application component using FadeIn as a wrapper
 * const App: FC = () => {
 *   const deselectAllPositions = () => {
 *     // Your deselect logic here
 *   };
 *
 *   return (
 *     <>
 *       <FadeInWrapper>
 *         <SmallButton type={"secondary"} onClick={deselectAllPositions}>
 *            Отменить выбор ({selectedStoppedDishes.length})
 *         </SmallButton>
 *       </FadeInWrapper>
 *       <Separator />
 *       <FadeInWrapper>
 *         <div className={"flex gap-2.5"}>
 *           <TurnOnOffButton />
 *           <ChangeModifiersButton />
 *           <ChangePriceButton />
 *           <ChangeCategoryButton />
 *         </div>
 *       </FadeInWrapper>
 *       <Separator />
 *       <FadeInWrapper>
 *         <TrashButton />
 *       </FadeInWrapper>
 *     </>
 *   );
 * };
 *
 * export default App;
 * ```
 */
const FadeIn: React.FC<FadeInProps> = ({ children, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`${className} transition-opacity duration-${FADE_IN_DURATION} ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      {children}
    </div>
  );
};

// Export both HOC and wrapper component
export { withFadeIn, FadeIn as FadeInWrapper };
