import React, { useCallback, useEffect, useState } from "react";
import Container from "../components/Container";
import Header from "../components/Header";
import Breadcrumbs from "../components/Breadcrumbs";
import FormContainer from "../components/FormContainer";
import CopyAlert from "../components/CopyAlert";
import PersonalDataForm from "../components/messageForms/PersonalDataForm";
import SuccessMessage from "../components/home/SuccessMessage";
import useFormsStore from "../store/formValuesStore";
import useDataStore from "../store/store";
import { postMessage } from "../api";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB в байтах

const LeaveMessage = () => {
    const [step, setStep] = useState(0);
    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const [code, setCode] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { values } = useFormsStore();
    const { data, setAnswers } = useDataStore();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const { t } = useTranslation();

    const handleFileChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const newFiles = Array.from(event.target.files).filter(
                    (file) => file.size <= MAX_FILE_SIZE,
                );
                setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
            }
        },
        [],
    );

    const handleFileRemove = useCallback((index: number) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }, []);

    const navigate = (code: string) => {
        setStep((prev) => prev + 1);
        setCode(code);
    };

    const submit = () => {
        setIsLoading(true);
        setAnswers(values);
        setIsSubmitting(true);
    };

    const handleNext = () => {
        if (step >= 14) {
            submit();
        } else {
            setStep((prev) => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (step > 0) {
            setStep((prev) => prev - 1);
        }
    };

    const onClickCopy = () => {
        navigator.clipboard.writeText(code).then(() => {
            setShowCopyAlert(true);
        });
    };

    const handleHideCopyAlert = () => {
        setShowCopyAlert(false);
    };

    useEffect(() => {
        if (isSubmitting) {
            const formData = new FormData();
            formData.append("data", JSON.stringify(data));

            selectedFiles.forEach((file, index) => {
                formData.append("files", file);
            });

            postMessage(formData, (code) => {
                setIsLoading(false);
                setIsSubmitting(false);
                navigate(code);
            });
        }
    }, [isSubmitting, data, selectedFiles]);

    return (
        <main className="bg-[#F5F5F5] min-h-screen">
            {isLoading && <Spinner />}
            <Header className="bg-[#F5F5F5]" />
            <div className="bg-[#F5F5F5] h-full px-4 md:px-8 lg:px-[163px] pb-8 md:pb-[60px]">
                <Breadcrumbs currentPage={t("messageTitle")} />
                <Container className="h-fit">
                    {step === 0 && <PersonalDataForm onClickNext={handleNext} />}
                    {step !== 0 && step < 15 && (
                        <FormContainer
                            selectedFiles={selectedFiles}
                            handleFileChange={handleFileChange}
                            handleFileRemove={handleFileRemove}
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            step={step}
                        />
                    )}
                    {step >= 15 && (
                        <SuccessMessage code={code} onClickCopy={onClickCopy} />
                    )}
                </Container>
            </div>
            <CopyAlert show={showCopyAlert} onHide={handleHideCopyAlert} />
        </main>
    );
};

export default LeaveMessage;