import React from "react";
import logo from "../assets/images/logo.png";
import LanguageLink from "./home/LanguageLink";
import { useTranslation } from "react-i18next";
import getLanguage from "../helpers/getCurrentLanguage";
import { Link } from "react-router-dom";

const Header = ({ className = "bg-white" }: { className?: string }) => {
  const currentLang = getLanguage();

  const { i18n } = useTranslation();
  const changeLangToEn = () => i18n.changeLanguage("en");
  const changeLangToKz = () => i18n.changeLanguage("kz");
  const changeLangToRu = () => i18n.changeLanguage("ru");

  return (
    <header
      className={`${className} w-full h-auto py-4 px-4 sm:px-6 md:px-8 lg:px-[163px] flex flex-row items-center justify-between`}
    >
      <Link to="/">
        <img
          className="h-[50px] w-auto sm:mb-0"
          src={logo}
          alt="hotline-logo"
        />
      </Link>
      <div className="flex gap-x-[15px]">
        <LanguageLink active={currentLang === "kz"} onClick={changeLangToKz}>
          қаз
        </LanguageLink>
        <LanguageLink active={currentLang === "ru"} onClick={changeLangToRu}>
          рус
        </LanguageLink>
        <LanguageLink active={currentLang === "en"} onClick={changeLangToEn}>
          eng
        </LanguageLink>
      </div>
    </header>
  );
};

export default Header;
