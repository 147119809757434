import React, { useState } from "react";
import Container from "../components/Container";
import Header from "../components/Header";
import Breadcrumbs from "../components/Breadcrumbs";
import Input from "../components/Input";
import Button from "../components/home/Button";
import { Link } from "react-router-dom";
import {
  getStatus,
  UserFrontStatusAnswer,
  UserFrontStatusAnswerFiles,
} from "../api";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";

const CheckStatus = ({
  onClickCheck,
}: {
  onClickCheck: (code: string) => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const { t } = useTranslation();

  return (
    <>
      <p className="mt-4 md:mt-[30px] text-base md:text-[18px] leading-6 md:leading-[32px] tracking-[-0.5px] mb-3 md:mb-[16px]">
        {t("enterCodeTitle")}
      </p>
      <Input
        inputProps={{ value: inputValue, onChange: handleChange }}
        className="w-28 md:w-[103px] uppercase"
        label={t("messageCodeHint")}
      />
      <Button
        onClick={() => onClickCheck(inputValue)}
        className="bg-[#A88C6D] text-white mt-6 md:mt-[30px] w-full md:w-auto"
      >
        {t("checkMessageStatusTitle")}
      </Button>
    </>
  );
};

interface StatusProps {
  data: UserFrontStatusAnswer;
}

const HTMLContentDisplay = ({ htmlContent }: { htmlContent: string }) => {
  return (
    <div
      className="space-y-4 text-justify"
      dangerouslySetInnerHTML={{
        __html: htmlContent
          // Удаляем пустые параграфы
          .replace(/<p><br><\/p>/g, "")
          // Удаляем одиночные br в конце параграфов
          .replace(/<br><\/p>/g, "</p>")
          // Добавляем классы для всех параграфов
          .replace(
            /<p(.*?)>/g,
            '<p$1 class="mb-4 last:mb-0 text-base md:text-[18px] leading-6 md:leading-[32px] tracking-[-0.5px]">',
          )
          // Убираем множественные переносы строк
          .replace(/[\r\n]+/g, "\n"),
      }}
    />
  );
};

const Status = ({ data }: StatusProps) => {
  const downloadFile = (file: UserFrontStatusAnswerFiles) => {
    const blob = new Blob([new Uint8Array(file.bytes)]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <p className="text-[#161C2D] font-bold text-xl md:text-[24px] leading-tight md:leading-[32px] tracking-[-0.5px] mb-4 md:mb-[20px]">
        Обращение № {data?.messageId}
      </p>

      <div className="w-full md:w-[715px]">
              <HTMLContentDisplay htmlContent={data.ReviewerComment} />
      </div>

      {data?.files && data.files?.length > 0 && (
        <div className="mt-6">
          <p className="text-[#161C2D] font-bold text-lg mb-3">
            Прикрепленные файлы:
          </p>
          <div className="flex flex-col gap-2">
            {data.files.map((file, index) => (
              <button
                key={index}
                onClick={() => downloadFile(file)}
                className="flex items-center gap-2 text-[#A88C6D] hover:underline"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
                {file.name}
              </button>
            ))}
          </div>
        </div>
      )}

      <Link to="/">
        <Button className="bg-[#A88C6D] text-white mt-6 md:mt-[30px] w-full md:w-auto">
          На Главную
        </Button>
      </Link>
    </>
  );
};

const WaitingStatus = () => (
  <>
    <p className="text-[#161C2D] font-bold text-xl md:text-[24px] leading-tight md:leading-[32px] tracking-[-0.5px] mb-4 md:mb-[20px]">
      Обращение № FF5240
    </p>
    <p className="w-full md:w-[715px] text-lg md:text-[24px] leading-tight md:leading-[32px] tracking-[-0.5px]">
      Обращение находится на рассмотрении. Ответ готовится в течение 15 рабочих
      дней.
    </p>
    <Link to="/">
      <Button className="bg-[#A88C6D] text-white mt-6 md:mt-[30px] w-full md:w-auto">
        На Главную
      </Button>
    </Link>
  </>
);

const CheckStatusPage = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<
    "WaitingStatus" | "Status" | "CheckStatus"
  >("CheckStatus");
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState<UserFrontStatusAnswer | null>(
    null,
  );

  const callback = (data: UserFrontStatusAnswer | null) => {
    setIsLoading(false);
    if (data) {
      setStatusData(data);
      setCurrentTab("Status");
    } else {
      setCurrentTab("CheckStatus");
    }
  };

  const handleClick = (code: string) => {
    if (!code.trim()) {
      return;
    }
    setIsLoading(true);
    getStatus(code, callback);
  };

  return (
    <main className="bg-[#F5F5F5] min-h-screen">
      {isLoading && <Spinner />}
      <Header className="bg-[#F5F5F5]" />
      <div className="bg-[#F5F5F5] px-4 md:px-[163px] pb-8 md:pb-[60px]">
        <Breadcrumbs currentPage={t("checkMessageStatusTitle")} />
        <Container>
          <p className="text-[#161C2D] font-bold text-2xl md:text-[30px] leading-tight md:leading-[40px] mb-6 md:mb-[30px]">
            Статус обращения
          </p>
          {currentTab === "CheckStatus" && (
            <CheckStatus onClickCheck={handleClick} />
          )}
          {currentTab === "Status" && statusData && (
            <Status data={statusData} />
          )}
          {currentTab === "WaitingStatus" && <WaitingStatus />}
        </Container>
      </div>
    </main>
  );
};

export default CheckStatusPage;
