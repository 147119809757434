const WhatsAppSvg = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27" cy="27" r="27" fill="#A88C6D" />
    <g clipPath="url(#clip0_248_129)">
      <path
        d="M29.7387 20.2129C30.7674 20.4136 31.7129 20.9168 32.454 21.6579C33.1951 22.399 33.6982 23.3445 33.899 24.3732M29.7387 16C31.876 16.2374 33.869 17.1945 35.3906 18.7142C36.9121 20.2338 37.8718 22.2257 38.1119 24.3626M37.0586 32.7674V35.9271C37.0598 36.2204 36.9998 36.5108 36.8822 36.7795C36.7647 37.0483 36.5924 37.2896 36.3762 37.4879C36.1601 37.6862 35.9049 37.8371 35.627 37.9311C35.3492 38.0251 35.0547 38.06 34.7626 38.0336C31.5216 37.6814 28.4085 36.574 25.6732 34.8002C23.1284 33.1831 20.9709 31.0256 19.3538 28.4808C17.5739 25.7331 16.4661 22.6048 16.1204 19.3493C16.0941 19.058 16.1287 18.7645 16.2221 18.4873C16.3154 18.2102 16.4654 17.9555 16.6626 17.7395C16.8598 17.5236 17.0997 17.351 17.3672 17.2328C17.6347 17.1147 17.9239 17.0535 18.2164 17.0532H21.376C21.8872 17.0482 22.3827 17.2292 22.7703 17.5625C23.1578 17.8958 23.411 18.3587 23.4825 18.8648C23.6159 19.876 23.8632 20.8688 24.2198 21.8244C24.3615 22.2013 24.3921 22.611 24.3081 23.0049C24.2241 23.3988 24.029 23.7603 23.7458 24.0467L22.4082 25.3843C23.9075 28.0211 26.0908 30.2043 28.7276 31.7037L30.0652 30.3661C30.3516 30.0829 30.7131 29.8877 31.107 29.8037C31.5008 29.7197 31.9105 29.7504 32.2875 29.8921C33.2431 30.2487 34.2359 30.496 35.2471 30.6294C35.7587 30.7015 36.226 30.9592 36.56 31.3535C36.894 31.7477 37.0715 32.2509 37.0586 32.7674Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_248_129">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(14 14)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WhatsAppSvg;
