import React from "react";

type Props = {
  onClick?: () => void;
  active?: boolean;
  children: React.ReactNode;
};

const LanguageLink: React.FC<Props> = ({ children, onClick, active }) => {
  return (
    <span
      onClick={onClick}
      className={`
        cursor-pointer
        inline-block
        pb-[1.5px]
        leading-none
        ${active ? "border-b border-[#232B42]" : "border-b border-transparent"}
      `}
    >
      {children}
    </span>
  );
};

export default LanguageLink;
