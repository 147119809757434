import React from "react";
import { FadeInWrapper } from "./FadeIn";
import { FAQItem } from "./home/sections/QuestionsSection";

const AccordionItem: React.FC<{
  item: FAQItem;
  isOpen: boolean;
  toggleAccordion: () => void;
}> = ({ item, isOpen, toggleAccordion }) => {
  return (
    <FadeInWrapper>
      <div className="border border-[#E7E9ED] rounded-[10px] overflow-hidden transition-all duration-300 ease-in-out">
        <button
          className="flex justify-between items-center w-full text-left px-4 md:px-7 py-4 md:py-6"
          onClick={toggleAccordion}
        >
          <span
            className={`${
              isOpen ? "font-bold" : "font-normal"
            } text-base md:text-xl leading-tight md:leading-8 text-[#161C2D]`}
          >
            {item.question}
          </span>
          <svg
            className={`w-4 h-4 md:w-6 md:h-6 transform transition-transform duration-300 ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 9l-7 7-7-7"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isOpen ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          <p className="px-4 md:px-7 pb-4 md:pb-6 text-sm md:text-base">
            {item.answer}
          </p>
        </div>
      </div>
    </FadeInWrapper>
  );
};

export default AccordionItem;
