import { FC, HTMLProps, PropsWithChildren } from "react";

type Props = HTMLProps<HTMLButtonElement> & PropsWithChildren;

const Button: FC<Props> = ({ onClick, className, children }) => {
  return (
    <button
      onClick={onClick}
      className={`${className} hover:opacity-80 transition-opacity px-5 rounded-[8px] cursor-pointer flex items-center justify-center text-[20px] leading-[32px] font-bold tracking-[-0.6px] h-[60px]`}
    >
      {children}
    </button>
  );
};

export default Button;
