import React, { PropsWithChildren } from "react";
import LayerSvg from "../../assets/svg/LayerSvg";

const Block = ({ children }: PropsWithChildren) => {
  return (
    <div className="w-full md:w-[345px] h-auto flex items-start">
      <div className="flex-shrink-0">
        <LayerSvg className="w-6 h-6 md:w-8 md:h-8" />
      </div>
      <p className="font-light text-sm md:text-[17px] ml-3 md:ml-[26px] leading-tight md:leading-[29px] tracking-[-0.2px]">
        {children}
      </p>
    </div>
  );
};

export default Block;
