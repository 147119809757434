import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionItem from "../../AccordionItem";

export type FAQItem = {
  question: string;
  answer: string;
};

const QuestionsSection: React.FC = () => {
  const { t } = useTranslation();
  const [openIndices, setOpenIndices] = useState<number[]>([]);
  const [visibleQuestions, setVisibleQuestions] = useState(3);

  const faqData: FAQItem[] = [
    { question: t("question1"), answer: t("answer1") },
    { question: t("question2"), answer: t("answer2") },
    { question: t("question3"), answer: t("answer3") },
    { question: t("question4"), answer: t("answer4") },
    { question: t("question5"), answer: t("answer5") },
    { question: t("question6"), answer: t("answer6") },
    { question: t("question7"), answer: t("answer7") },
    { question: t("question8"), answer: t("answer8") },
    { question: t("question9"), answer: t("answer9") },
    { question: t("question10"), answer: t("answer10") },
    { question: t("question11"), answer: t("answer11") },
    { question: t("question12"), answer: t("answer12") },
    { question: t("question13"), answer: t("answer13") },
    { question: t("question14"), answer: t("answer14") },
    { question: t("question15"), answer: t("answer15") },
  ];

  const toggleAccordion = (index: number) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index],
    );
  };

  const showMoreQuestions = () => {
    setVisibleQuestions((prev) => Math.min(faqData.length, faqData.length));
  };

  return (
    <section className="px-4 md:pl-[17%] mt-12 md:mt-[90px]">
      <h1 className="text-[#17335D] font-bold text-2xl md:text-5xl mb-6 md:mb-12 leading-tight">
        {t("faqTitle")}
      </h1>
      <div
        className={`w-full md:max-w-[880px] ${
          visibleQuestions < faqData.length ? "" : "mb-6 md:mb-[40px]"
        } space-y-2.5`}
      >
        {faqData.slice(0, visibleQuestions).map((item, index) => (
          <AccordionItem
            key={index}
            item={item}
            isOpen={openIndices.includes(index)}
            toggleAccordion={() => toggleAccordion(index)}
          />
        ))}
      </div>
      {visibleQuestions < faqData.length && (
        <p
          onClick={showMoreQuestions}
          className="text-[#473BF0] cursor-pointer mb-6 md:mb-[40px] text-lg md:text-[24px] leading-tight md:leading-[34px] tracking-[-0.5px] mt-4 md:mt-[26px] underline"
        >
          Показать все
        </p>
      )}
    </section>
  );
};

export default QuestionsSection;
