import React, { HTMLProps, useId } from "react";

type Props = {
  label?: string;
  className?: string;
  inputProps?: HTMLProps<HTMLInputElement>;
};

const Input = ({
  label,
  className = "w-full md:w-[480px]",
  inputProps,
}: Props) => {
  const htmlFor = useId();
  return (
    <div className="flex flex-col w-full">
      <label
        className="mb-1 md:mb-[6px] text-black text-xs md:text-[14px] leading-5 md:leading-[26px] tracking-[-0.1px]"
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <input
        {...inputProps}
        id={htmlFor}
        className={`${className} h-10 md:h-[50px] rounded-[8px] border border-[#E7E9ED] px-3 md:px-[16px] text-black text-sm md:text-[14px] leading-5 md:leading-[26px] tracking-[-0.1px]`}
        type="text"
      />
    </div>
  );
};

export default Input;
