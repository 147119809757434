import { FC } from "react";
import FormInput from "../FormInput";
import useFormsStore from "../../store/formValuesStore";
import { useTranslation } from "react-i18next";

const NinthForm: FC = () => {
  const { values, setValue } = useFormsStore();
  const handleChange = (value: string) => {
    setValue(9, value);
  };
  const { t } = useTranslation();

  return (
    <div>
      <FormInput
        value={values[9].value}
        onChangeText={handleChange}
        label={t("questions.10")}
      />
    </div>
  );
};

export default NinthForm;
