import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#20283E] items-center flex justify-center w-full mb-auto h-16 md:h-[84px]">
      <p className="leading-tight md:leading-[34px] text-white font-normal tracking-[-0.5px] text-base md:text-[24px] text-center px-4">
        2024 АО «Самрук-Қазына»
      </p>
    </footer>
  );
};

export default Footer;
