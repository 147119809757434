import axios from "axios";

const apiInstance = axios.create({
    baseURL: "http://195.93.152.76/",
});


const postMessage = (formData: FormData, navigate: (code: string) => void) => {
    apiInstance
        .post("/api/ExtDictionary/PostQuestionnaireData", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            navigate(response.data);
        })
        .catch((error) => {
            console.error("Error:", error);
            navigate("");
        });
};


export interface UserFrontStatusAnswerFiles {
  name: string;
  bytes: number[];
  path: string;
}

export interface UserFrontStatusAnswer {
  ReviewerComment: string;
  messageText: string;
  messageId: string;
  files: UserFrontStatusAnswerFiles[];
}

type GetStatus = (
  data: string,
  callback: (status: UserFrontStatusAnswer | null) => void,
) => void;

const getStatus: GetStatus = (data, callback) => {
  apiInstance
    .get<UserFrontStatusAnswer>(
        `/api/ExtDictionary/GetStatus?requestCode=${data}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(null);
    });
};

export { postMessage, getStatus };
