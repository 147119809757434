import { FC } from "react";
import FormInput from "../FormInput";
import useFormsStore from "../../store/formValuesStore";
import { useTranslation } from "react-i18next";

const FirstForm: FC = () => {
  const { values, setValue } = useFormsStore();
  const handleChange = (value: string) => {
    setValue(0, value);
  };

  const { t } = useTranslation();

  return (
    <div>
      <FormInput
        value={values[0].value}
        onChangeText={handleChange}
        label={t("questions.1")}
      />
    </div>
  );
};

export default FirstForm;
