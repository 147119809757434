import { create } from "zustand";

type Element = {
  value: string;
  id: number;
};

type State = {
  values: Element[];
};

type Actions = {
  setValue: (id: number, value: string) => void;
  resetForm: () => void;
  setMultipleValues: (newValues: Partial<Element>[]) => void;
  getValueById: (id: number) => string | undefined;
};

type Store = State & Actions;

const initialValues: Element[] = Array.from({ length: 17 }, (_, index) => ({
  id: index,
  value: "",
}));

const useFormsStore = create<Store>((set, get) => ({
  values: initialValues,

  setValue: (id, value) =>
    set((state) => ({
      values: state.values.map((element) =>
        element.id === id ? { ...element, value } : element,
      ),
    })),

  resetForm: () => set({ values: initialValues }),

  setMultipleValues: (newValues) =>
    set((state) => ({
      values: state.values.map((element) => {
        const newValue = newValues.find((v) => v.id === element.id);
        return newValue ? { ...element, ...newValue } : element;
      }),
    })),

  getValueById: (id) =>
    get().values.find((element) => element.id === id)?.value,
}));

export default useFormsStore;
