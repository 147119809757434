const MailIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27" cy="27" r="27" fill="#A88C6D" />
    <rect width="34" height="34" transform="translate(10 10)" fill="#A88C6D" />
    <path
      d="M15.6667 15.6667H38.3333C39.8917 15.6667 41.1667 16.9417 41.1667 18.5V35.5C41.1667 37.0584 39.8917 38.3334 38.3333 38.3334H15.6667C14.1083 38.3334 12.8333 37.0584 12.8333 35.5V18.5C12.8333 16.9417 14.1083 15.6667 15.6667 15.6667Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.1667 18.5L27 28.4167L12.8333 18.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MailIcon;
